<template>
  <div class="container">
    <h4 class="text-center my-4">Учебный календарь</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">



      <TabView class="mt-4">
        <TabPanel v-for="studyLevel in adviserChoose_form.studyLevels" :key="studyLevel.id" :header="studyLevel.name">

          <Button icon="pi pi-plus" label="Добавить элемент" class="p-button-rounded"
                  @click="addElement(studyLevel.id)"/>


          <div v-for="(element, elementIndex) in educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevel.id)" :key="elementIndex" class="my-4 border rounded-3 p-2">


            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteElement(element)"></i>
            </div>

            <div class="row mt-2 mb-2">
              <div class="col-md-5">Курс</div>
              <div class="col-md-7">

                <Dropdown
                          @change="changeCourse(elementIndex, studyLevel.id, $event)"
                          :options="studyCourses"
                          optionLabel="name" optionValue="name"
                          :placeholder="element?.study_course != null ? element?.study_course : 'Выберите курс'" class="w100p"/>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Уровень обучения</div>
              <div class="col-md-7">
<!--                <p>{{adviserChoose_form.studyLevels.find(i=>i.id == studyLevel.id).name}}</p>-->
                <Dropdown
                    :options="adviserChoose_form.studyLevels"
                    optionValue="name"
                    optionLabel="name"
                    :placeholder="adviserChoose_form.studyLevels.find(i=>i.id == studyLevel.id).name"
                    class="w100p" disabled/>
              </div>
            </div>


            <div class="row mb-2">
              <div class="col-md-5">Начало</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Начало"
                       :value="element.start_date"
                       @change="changeDate(elementIndex, studyLevel.id, 'start_date', $event)">
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Конец</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Конец"
                       :value="element.end_date"
                       @change="changeDate(elementIndex, studyLevel.id,'end_date', $event)">
              </div>
            </div>



          </div>
          <div class="mt-3 text-center" v-if="educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevel.id).length > 0">
            <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded"
                    :loading="loadingSave" @click="save"/>
          </div>




        </TabPanel>
      </TabView>






    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {
  convertDateToTimestamp,
  convertTimestampToDate,
  convertDateInputToTimestamp
} from "@/utils/helpers/date.helpers"

export default {
  name: 'EducationCalendar',
  data() {
    return {
      loading: true,
      elements: [],
      studyCourses: [
        {
          name: 1
        },
        {
          name: 2
        },
        {
          name: 3
        },
        {
          name: 4
        },
      ],
      loadingSave: false,

    }
  },
  computed: {
    ...mapState('educationCalendar', ['educationCalendar_form']),
    ...mapState('adviserChoose', ['adviserChoose_form'])
  },

  methods: {

    ...mapActions('educationCalendar', ['GET_EDUCATION_CALENDAR','POST_EDUCATION_CALENDAR', 'DELETE_EDUCATION_CALENDAR', 'PUT_EDUCATION_CALENDAR']),
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),

    ...mapMutations('educationCalendar', ['ADD_ELEMENT', 'DELETE_ELEMENT', 'SET_STUDY_COURSE', 'SET_DATE']),
    convertDateInputToTimestamp,
    changeCourse(elementIndex, studyLevelId, e) {
      let value = e.value
      this.SET_STUDY_COURSE({elementIndex, studyLevelId, value})
    },
    changeDate(elementIndex,studyLevelId, property, e) {
      //let date = convertDateInputToTimestamp(e.target.value)
      let date = e.target.value
      this.SET_DATE({elementIndex, studyLevelId, property, date})

    },


    addElement(study_level_id) {
      this.ADD_ELEMENT(study_level_id)

    },
    async deleteElement(element) {
      console.log(element, 'delete element')
      if (element.id != null) {
        await this.DELETE_EDUCATION_CALENDAR(element.id)
        await this.GET_EDUCATION_CALENDAR()
      }
      else {
        this.DELETE_ELEMENT(element)
      }


    },

    async save() {
      this.loadingSave = true


      console.log(this.educationCalendar_form.educationCalendar.filter(i=>!i.id), 'save')


      if (this.educationCalendar_form.educationCalendar.filter(i=>!i.id).length > 0) {
        const postRes = await this.POST_EDUCATION_CALENDAR()
        if (postRes) {
          await this.GET_EDUCATION_CALENDAR()
          this.$message({title: 'Успешно сохранено'})
        }
      }
      else {
        const putRes = await this.PUT_EDUCATION_CALENDAR()
        if (putRes) {
          await this.GET_EDUCATION_CALENDAR()
          this.$message({title: 'Успешно обновлено'})
        }
      }




      this.loadingSave = false
    },


  },
  async mounted() {
    await this.GET_STUDY_LEVELS()
    await this.GET_EDUCATION_CALENDAR()

    this.loading = false
  },
}

</script>